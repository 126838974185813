<template>
  <v-container id="user-profile-view" fluid tag="section">
    <div style="display: flex; justify-content: center">
      <v-col cols="12" md="10">
        <app-card class="mt-4 text-center">
          <v-img
            class="rounded-circle elevation-6 mt-n12 d-inline-block"
            src="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
            width="128"
          />

          <v-card-text class="text-center">
            <v-form ref="profileForm">
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="First Name"
                      v-model="editedItem.firstName"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Middle Name"
                      v-model="editedItem.middleName"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Last Name"
                      v-model="editedItem.lastName"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Username"
                      v-model="editedItem.username"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Email" v-model="editedItem.email" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Date of Birth"
                      v-model="editedItem.dateOfBirth"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Telephone"
                      v-model="editedItem.telephone"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Phone No"
                      v-model="editedItem.phoneNumber"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Adress" v-model="editedItem.address" />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Map Latitude"
                      v-model="editedItem.mapLatitude"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Map Longitude"
                      v-model="editedItem.mapLongitude"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>

            <v-btn
              class="mt-5 mr-0"
              color="primary"
              min-width="100"
              @click="updateProfile"
            >
              Update Profile
            </v-btn>
          </v-card-text>
        </app-card>
      </v-col>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "UserProfileView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    editedItem: {
      id: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      telephone: "",
      phoneNumber: "",
      profilePhoto: "",
      username: "",
      email: "",
      mapLatitude: 0,
      mapLongitude: 0,
    },
  }),
  created() {
    this.editedItem = Object.assign({}, this.getProfile);
  },
  computed: {
    getProfile() {
      return this.$store.state.authUser.profile;
    },
  },
  watch: {
    getProfile: {
      handler() {
        this.editedItem = Object.assign({}, this.getProfile);
      },
      deep: true,
    },
  },
  methods: {
    updateProfile() {
      if (this.$refs.profileForm.validate()) {
        this.$store
          .dispatch("authUser/updateProfile", this.editedItem)
          .then((response) => {
            this.$router.push("/").catch(() => {});
            this.$toast.success(response, "Success", {
              position: "topRight",
            });
          })
          .catch((err) => {
            this.$toast.error(err, "Error", {
              position: "topRight",
            });
          });
      }
    },
  },
};
</script>
